import { graphql } from "gatsby"
import React, { useContext } from "react"
import Button from "../../components/Button"
import { MetaTagsContext } from "../../layouts"

const MetaInfo = ({ title, body }) => (
  <div className="pb-4">
    <div className="uppercase tracking-widest text-16px">{title}:</div>
    <div className="md:text-24px">{body}</div>
  </div>
)

const Component = ({ location, data }) => {
  const companyData = data.strapiCompanyProfiles
  const logo = companyData.logo
  const hasTeamPicture = !!companyData.teamPicture
  const hasMediumPicture =
    hasTeamPicture &&
    companyData.teamPicture.formats &&
    companyData.teamPicture.formats.medium &&
    companyData.teamPicture.formats.medium.url
  const teamPicture = hasTeamPicture
    ? companyData.teamPicture.formats && hasMediumPicture
      ? companyData.teamPicture.formats.medium.url
      : companyData.teamPicture.url
    : null

  const { setMetaTags } = useContext(MetaTagsContext)
  setMetaTags(data.strapiCompanyProfiles.seo)

  return (
    !!logo && (
      <div
        className="relative overflow-hidden pt-10
        bg-white"
      >
        {hasTeamPicture && (
          <div
            className="hidden absolute top-0 bottom-0 left-0 w-1/3 bg-cover bg-no-repeat
        lg:block"
            style={{
              backgroundImage: `url( ${teamPicture})`,
              filter: "grayscale(100%)",
            }}
          >
            <div className="absolute bottom-5 left-5 z-20 text-mvp-dark-blue"></div>
          </div>
        )}

        <div className="container">
          <div className="flex">
            <div
              className="hidden
            lg:block md:w-4/12
          "
            ></div>

            <div
              className="flex flex-col items-center 
            lg:pb-0 lg:w-8/12 lg:justify-evenly
            "
            >
              {!!logo && (
                <img
                  className="max-w-200px md:max-w-250px"
                  src={`${
                    logo.formats && logo.formats.small
                      ? logo.formats.small.url
                      : logo.url
                  }`}
                />
              )}
              <div
                className="py-4 font-light text-36px leading-tight
              md:text-4-5xl
            lg:max-w-3xl lg:text-4-5xl"
              >
                {companyData.portfolioTitle}
              </div>

              <div
                className="pb-8
              md:text-24px md:py-6
             lg:max-w-2xl lg:text-24px"
              >
                {companyData.description}
              </div>

              <Button
                label={`Visit ${companyData.name}`}
                url={companyData.websiteUrl}
                className="mb-10 w-full max-w-xs text-center"
              />

              <div
                className="grid grid-cols-2 md:grid-cols-4 w-full 
                md:justify-items-center
            "
              >
                <MetaInfo title="Ceo" body={companyData.ceo} />
                <MetaInfo title="Founded" body={companyData.founded} />
                <MetaInfo title="Location" body={companyData.location} />
                <MetaInfo title="Stage" body={companyData.stage} />
              </div>

              <div
                className="pt-12
                md:pb-0
                xl:pt-10
            "
              ></div>
            </div>
          </div>
        </div>

        {teamPicture && (
          <div
            className="relative overflow-hidden h-100vw
      sm:h-600px
      lg:hidden
      "
          >
            <div
              className="bg-no-repeat bg-cover bg-home-founder-portrait h-full"
              style={{
                backgroundImage: `url( ${teamPicture})`,
                filter: "grayscale(100%)",
              }}
            ></div>

            <div
              className="absolute top-0 bottom-0 left-0 right-0 z-10"
              style={{
                background: "rgb(255,255,255)",
                background:
                  "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%)",
              }}
            ></div>
          </div>
        )}
      </div>
    )
  )
}

export const query = graphql`
  query ($id: String) {
    strapiCompanyProfiles(id: { eq: $id }) {
      websiteUrl
      url
      stage
      portfolioTitle
      name
      location
      industry
      founded
      description
      ceo
      seo {
        title
        description
      }
      teamPicture {
        url
        formats {
          large {
            url
          }
          medium {
            url
          }
          small {
            url
          }
        }
      }
      logo {
        url
        ext
        formats {
          large {
            url
            ext
          }
          thumbnail {
            url
            ext
          }
          small {
            url
            ext
          }
          medium {
            url
            ext
          }
        }
      }
    }
  }
`

export default Component
